import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div class="home">
        <div class="home-left">
          <div>
            <h3><Link to="/about">Where Fort Worth Learn to Innovates</Link></h3>
            <p>Each month the Innovators Forum welcomes local innovators and experts to explore the many aspects of starting, scaling, and maintaining new, innovative businesses.</p>
          </div>
        </div>
        <div class="home-right">
          <div>
            <h2>2024</h2>
            <h3><Link to="/competition">Innovation Competition</Link></h3>
            <p>The Innovators Forum is proud to announce the first annual Innovation Competition in collaboration with TechFW and the City of Fort Worth Economic Development Department.</p>
          </div>
        </div>
      </div>
      <div class="home-event">
        <div class="home-event-left">
          <h3><Link to="/event">Innovation Management:  A Foundation for Technological Economic Development</Link></h3>
          <div>
            <Link to="/event"><img src="./image/PaulJCorson.jpg" alt="Joshua Brockway"/></Link>
            <p><Link to="/event">Paul J. Corson, Executive Director, Center for Entrepreneurship and Technology Development, UTA</Link></p>
          </div>
          <div>
            <h3><Link to="/event">Friday, September 13 2024 Noon-1PM</Link></h3>
          </div>
        </div>
        <div class="home-event-middle">
          <h3><Link to="/event">Defense Innovation - A Startup Founder's View</Link></h3>
          <div>
            <Link to="/event"><img src="./image/JamesThompson.jpg" alt="James Thompson"/></Link>
            <p><Link to="/event">James Thompson, Entrepreneur and Investor</Link></p>
          </div>
          <div>
            <h3><Link to="/event">Friday, July 12, 2024 Noon-1PM</Link></h3>
            <p><Link to="/event">Details</Link></p>
          </div>
        </div>
        <div class="home-event-right">
          <h3><Link to="/event">Navigating Legal Challenges for Startups</Link></h3>
          <div>
            <Link to="/event"><img src="./image/AndrewKussmaul.jpg" alt="Andrew Kussmaul"/></Link>
            <p><Link to="/event">Andrew Kussmaul, Partner, Peters Kussmaul Crosier PLLC</Link></p>
          </div>
          <div>
            <h3><Link to="/event">Friday, August 16, 2024 Noon-1PM</Link></h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
