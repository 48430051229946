import React from "react";
import { Link } from "react-router-dom";

const Event = () => {
    return (
        <>

            <div class="event">
                <div class="event-left">
                    <div style={{ backgroundColor: "#05B3F3" }}>
                        <div>
                            <img src="./image/PaulJCorson.jpg" alt="Paul J. Corson"/>
                            <h4>Paul J. Corson, Executive Director, Center for Entrepreneurship and Technology Development, UTA</h4>
                        </div>
                        <div>
                            <h3>Friday, September 13, 2024 Noon-1PM</h3>
                            <p><Link to="https://www.linkedin.com/events/7209578311398629376/"><img src="./image/RsvpButton.jpg" alt="RSVP"/></Link></p>
                            <p><Link to="https://www.hscnext.com">HSC Next</Link></p>
                            <p>550 Bailey Ave #510</p>
                            <p>Fort Worth, TX 76107</p>
                            <p><Link to="https://www.google.com/maps/place/550+Bailey+Ave+%23510,+Fort+Worth,+TX+76107">Directions</Link></p>
                        </div>
                    </div>
                </div>
                <div class="event-right">
                    <h2>Innovation Management:  A Foundation for Technological Economic Development</h2>
                    <p>What is the university’s role in regional innovation ecosystems?  How can research professors increase the likelihood that their work will lead to societally useful and commercially valuable intellectual property?  What can the university administration do to support market-based opportunities, startup formation, and industry engagement related to university research?</p>
                    <p>Please join Paul Corson, Executive Director of the UTA Center for Entrepreneurship and Technology Development, for a discussion on a new approach to generating commercial impact from university research.</p>
                    <p>Paul has spent three decades pursuing entrepreneurial initiatives in the United States, Europe, Latin America, the Middle East, and Asia.  His focus on entrepreneurship cuts across the public and private sectors, as well as NGOs and higher education.  Paul has played leadership roles in two startups, developed and implemented public policy at the federal level for the Secretary of Commerce, NASA, and the White House, and spurred technology commercialization, access to capital, and business formation in higher education.</p>
                    <p><Link to="https://www.linkedin.com/in/pauljcorson2028/"><img src="./image/LinkedinLogo.png" alt="LinkedIn"/></Link></p>
                </div>
            </div>

            <div class="event">
                <p>Parking is free. Park on the 4th floor of the parking structure. Please do not park in the visitor spots to the north and east of 550 Bailey.</p>
                <ol>
                    <li>Turn into the lot for 550 Bailey and immediately turn right (north) to access the gate that leads to the parking garage.</li>
                    <li>If the gate arm is down, press the silver button for the intercom and tell the guard you are here for the event at HSC.</li>
                    <li>Drive to the 4th level and park close to the elevator bank</li>
                    <li>Take the elevator to the ground floor and enter the building from the west side</li>
                    <li>Walk to the lobby and take the elevators up to the 5th floor. Ask the security guard to direct you if needed.</li>
                    <li>Exit the elevator and enter the double glass doors. Tell the receptionist at the front desk that you are here for the FWIC event and she will direct you.</li>
                </ol>
            </div>

        </>
    );
};

export default Event;
